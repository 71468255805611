import { useState } from 'react';
import { login } from '../api';
import { Button, TextField } from '@mui/material';
import Grid from '@mui/material/Grid2';
import styled from 'styled-components';


function Login() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [token, setToken] = useState('');

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await login(username, password);
      setToken(response.token);  // Store token in state
    } catch (error) {
      console.error(error);
      alert('Login failed');
    }
  };

  return (
    <Wrapper>
      <form onSubmit={handleLogin}>
          <Grid container spacing={2} alignItems="center">
            {/* First Input Field */}
            <Grid item xs={12} sm={4}>
                    
            <TextField
                label="Username"
                variant="outlined"
                fullWidth
                value={username}
                onChange={(e) => setUsername(e.target.value)}
            />
            </Grid>

            {/* Second Input Field */}
            <Grid item xs={12} sm={4}>   
            <TextField
                type="password"
                label="Password"
                variant="outlined"
                fullWidth
                value={password}
                onChange={(e) => setPassword(e.target.value)}
            />
            </Grid>

            {/* Button */}
            <Grid item xs={12} sm={4}>
            <Button type="submit" variant="contained" color="primary">
            Login
                </Button>
            </Grid>
          </Grid>
      </form>

      {token && (
        <form>
          <h3>Your JWT Token</h3>
          <textarea
            readOnly
            value={token}
            rows="5"
            cols="50"
          />
        </form>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
    margin: 20;
`;
export default Login;
