import { useState } from 'react';
import { register } from '../api';
import { Button, TextField } from '@mui/material';
import Grid from '@mui/material/Grid2';
import styled from 'styled-components';

function Register() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const handleRegister = async (e) => {
    e.preventDefault();
    try {
      const response = await register(username, password);
      alert(response.message);
    } catch (error) {
      console.error(error);
      alert('Registration failed');
    }
  };

  return (
    <Wrapper>
    <form onSubmit={handleRegister}>

          <Grid container spacing={2} alignItems="center">
            {/* First Input Field */}
            <Grid item xs={12} sm={4}>
                <TextField
                    label="Username"
                    variant="outlined"
                    fullWidth
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                />
            </Grid>

            {/* Second Input Field */}
            <Grid item xs={12} sm={4}>
                <TextField
                    type="password"
                    label="Password"
                    variant="outlined"
                    fullWidth
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
            </Grid>

            {/* Button */}
            <Grid item xs={12} sm={4}>
            <Button type="submit" variant="contained" color="primary">
            Register
                </Button>
            </Grid>
          </Grid>

    </form>
    </Wrapper>
  );
}
const Wrapper = styled.div`
    margin-bottom: 20px;
`;
export default Register;
