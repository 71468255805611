import React from 'react';
import Register from './components/Register';
import Login from './components/Login';
import { Card, CardContent, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';

function App() {
  return (
    <div className="App">
      <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
        minWidth:569,
        padding: 2,
      }}
    >
      <Card sx={{ maxWidth: 700, width: '100%', padding: 2 }}>
        <CardContent>
          <Typography variant="h5" gutterBottom>
          JWT Auth App
          </Typography>
          <Register />
          <Login />
        </CardContent>
      </Card>
    </Box>
    </div>
  );
}

export default App;
